import StripeDialog from './StripeDialog.vue';

/**
 * Class representing a Stripe Web Plugin for handling Stripe payments within a dialog.
 */
export class StripeWebPlugin {
  /**
   * Creates an instance of StripeWebPlugin.
   * @param {Function} dialogPlugin - The dialog plugin used to display the Stripe checkout.
   */
  constructor(dialogPlugin) {
    this.dialogPlugin = dialogPlugin;
  }

  /**
   * Initializes and mounts the Stripe checkout process.
   *
   * This method asynchronously loads the Stripe library, initializes the checkout session,
   * and mounts the checkout UI into a dialog. It returns a promise that resolves when the
   * checkout process is either completed or dismissed by the user.
   *
   * @param {Object} a2u - The A2U instance
   * @param {string} apiKey - The public Stripe API key.
   * @param {string} clientSecret - The client secret for the Stripe checkout session.
   * @param {string} type - The type of the product being purchased.
   * @returns {Promise<Object>} A promise that resolves with an object indicating the checkout state.
   */
  async mount(a2u, apiKey, clientSecret, type) {
    let isComplete = false;

    // Initialize the dialog with Stripe checkout UI.
    const dialog = this.dialogPlugin({
      component: StripeDialog,
      componentProps: {
        a2u,
        apiKey,
        clientSecret,
        type,
        onComplete() {
          isComplete = true;

          dialog.hide();
        },
      },
    });

    // Return a promise that resolves when the checkout dialog is closed.
    return new Promise((resolve) => {
      const dialogCb = () => {
        // Resolve the promise with the checkout state when the dialog is closed.
        resolve({
          state: isComplete ? 'success' : 'cancel',
        });
      };

      // Attach the callback to dialog events.
      dialog.onOk(dialogCb)
        .onCancel(dialogCb)
        .onDismiss(dialogCb);
    });
  }
}
