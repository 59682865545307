<template>
  <q-dialog class="stripe-dialog z-max" full-width position="bottom">
    <q-card>
      <div class="row justify-end">
        <q-btn flat round icon="close" v-close-popup />
      </div>

      <q-card-section>
        <div ref="stripeCheckout" />

        <q-btn
          class="stripe-pay-btn full-width"
          @click="purchase"
          :disable="!isReady"
          :loading="isBusy"
          :label="btnLabel"
          text-color="white"
          unelevated
          no-caps
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: 'StripeDialog',

  props: ['a2u', 'apiKey', 'clientSecret', 'onComplete', 'type'],

  data() {
    return {
      stripe: null,
      elements: null,
      isBusy: false,
      isReady: false,
    };
  },

  computed: {
    isSubscription() {
      return this.type === 'subs';
    },
    btnLabel() {
      const defaultValue = this.isSubscription ? 'Subscribe' : 'Pay';

      if (typeof this.a2u?.currentDiagramComonent?.interpretString !== 'function') {
        return defaultValue;
      }

      const key = this.isSubscription ? 'subscribe' : 'pay'

      return this.a2u?.currentDiagramComonent?.interpretString({
        isLocalizable: true,
        localeAlias: `${this.a2u.moduleId}.iap.payBtn.${key}`,
        value: defaultValue,
      });
    },
  },

  methods: {
    async purchase() {
      if (this.isBusy) {
        return;
      }

      this.isBusy = true;

      try {
        const {paymentIntent} = await this.stripe.confirmPayment({
          elements: this.elements,
          redirect: 'if_required',
          confirmParams: {
            return_url: window.location.href,
          },
        });

        if (paymentIntent?.status === 'succeeded') {
          this.onComplete();
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.isBusy = false;
      }
    },
  },

  async created() {
    // Dynamically import Stripe.js
    const { loadStripe } = await import('@stripe/stripe-js');

    // Load Stripe.js
    const stripe = await loadStripe(this.apiKey);

    this.stripe = stripe;

    this.elements = stripe.elements({
      clientSecret: this.clientSecret,
      loader: 'always',
      appearance: {
        theme: 'stripe',
        labels: 'floating',
        variables: {
          colorText: '#6d6e78',
          colorTextPlaceholder: '#6d6e78',
        },
      },
    });

    const paymentElement = this.elements.create('payment', {
      paymentMethodOrder: ['google_pay', 'apple_pay', 'card'],
      layout: {
        type: 'accordion',
      },
    });

    paymentElement.on('ready', () => {
      this.isReady = true;
    });

    paymentElement.mount(this.$refs.stripeCheckout);
  },
}
</script>

<style lang="scss">
  body.q-ios-padding .stripe-dialog {
    .q-dialog__inner {
      padding-top: 21px !important;

      & > div {
        max-height: calc(var(--screen-height, 100vh) - 21px) !important;
      }
    }
  }

  .q-btn.stripe-pay-btn {
    background-color: #31c48d;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    margin-top: 12px;
    height: 64px;
  }
</style>
