import {reactive} from 'vue';
import {ErrorAnswer} from 'a2u-renderer-common/src/utils/ErrorAnswer';
import {IapPostPurchase} from './post-purchase/IapPostPurchase';

const STORE_UPDATE_INTERVAL = 30; // 30 seconds

export class IAPManager {

    // Manager instance
    static instance = null;

    updateTimeout = null;

    /**
     * Constructor
     */
    constructor(device) {

        // Testing mode
        this.device = device
        this.isTesting = this.device.a2u.runMode !== 'release'
        this.storeState = "init"
        this.iapPlugin = device.getPlugin('AbIAPClient')
        this.fromA2UToPlatform = {}
        this.fromPlatformToA2U = {}
        this.meta = {};
        this.waitSyncPurchases = false;
        this.syncPurchasesMap = {};
        this.targetPlatform = this.device.a2u.source.modules[this.device.a2u.getModuleId()]?.type === 'web' ? 'web' : 'mobile';

        this.purchasesMap = reactive({}); // {productId: boolean}
        this.groupsPurchasesMap = reactive({}); // {groupId: boolean}
    }

    /**
     * Get instance
     * @return {AdManager}
     */
    static getInstance(device) {
        if(!this.instance) this.instance = new IAPManager(device);
        return this.instance;
    }

    /**
     * Init iap
     * @return {Promise<void>}
     */
    async init(config) {

        // Store config
        this.config = config;

        // Load purchase adapter if device is native
        if(this.device.getPlatform() !== 'web' || this.targetPlatform === 'web') {
            try {
                // Init iap plugin
                await this.iapPlugin.init({
                    ...config,
                    products: (config?.products || []).map(p => {

                        let idField = 'googleId';

                        if (this.targetPlatform === 'web') {
                            idField = `stripeId${this.device.a2u.runMode === 'release' ? 'Release' : 'Stage'}`;
                        } else if (this.device.getPlatform() === 'ios') {
                            idField = 'appleId';
                        }

                        // Get os specific id
                        const id = p[idField];

                        // Save id to a2u id
                        this.fromA2UToPlatform[p.productId] = id;
                        this.fromPlatformToA2U[id] = p.productId;

                        // Save meta
                        this.meta[p.productId] = {
                            googleId: p?.googleId,
                            appleId: p?.appleId,
                            stripeId: p[`stripeId${this.device.a2u.runMode === 'release' ? 'Release' : 'Stage'}`],
                            group: p.group,
                        };

                        // Set purchase map
                        this.purchasesMap[p.productId] = false;
                        this.groupsPurchasesMap[p.group] = false;

                        if (!id) {
                            return false;
                        }

                        // Return product
                        return {
                            id,
                            type: p.type === 'subscription' ? 'subs' : 'inapp',
                            isConsumable: p?.isConsumable || 0,
                        }
                    }).filter(Boolean),
                })

                // Inited
                //this.log("IAP init, cached products: ", JSON.stringify(await this.getProductList()))

                // Update store state
                this.update();

                this.waitSyncPurchases = this.syncPurchases();

                // Add success purchase listener
                this.iapPlugin.addListener('successPurchase', async (platformProductId, res) => {
                    const postPurchaseService = new IapPostPurchase(this);

                    const productId = this.fromPlatformToA2U[platformProductId];

                    await postPurchaseService.postPurchase(productId, res);
                });
            } catch (e) {
                this.log("store init error", e)

                this.logError(e);

                this.storeState = 'error'
            }
        } else {
            // Store is ready
            this.storeState = "ready"
        }
    }

    /**
     * Update store
     * @return {Promise<void>}
     */
    async update() {
        // Check if store is updating
        if (this.storeState === "updating") {
            await this.waitForStoreReady();
            return;
        }

        // Clear timeout
        clearTimeout(this.updateTimeout);

        const attemptUpdate = async (attempt = 0) => {
            const startTime = Date.now();

            try {
                // Set updating state
                this.storeState = "updating";

                // Update store
                await this.iapPlugin.update();

                // Store is ready
                this.storeState = "ready";

                // Get products
                const products = await this.getProductList();

                // Check if products list is empty
                if (!products || !Object.keys(products).length) {
                    throw new Error('Empty products list');
                }

                // Inited
                this.log("IAP update inited, products: ", JSON.stringify(products));
            } catch (e) {
                this.log(`IAP update error: ${e?.message}`, e);

                // Log error only on the first attempt
                if (attempt === 0) {
                    this.logError(e);
                }

                this.storeState = "error";

                const executionTime = Date.now() - startTime;
                const timeToWait = Math.max(0, (STORE_UPDATE_INTERVAL * 1000) - executionTime);

                this.log(`Update attempt failed. Retrying in ${timeToWait/1000} seconds...`);

                // Retry
                this.updateTimeout = setTimeout(() => {
                    return attemptUpdate(attempt + 1);
                }, timeToWait);
            }
        };

        return attemptUpdate();
    }

    /**
     * Manage subscriptions
     * @return {Promise<*>}
     */
    async manageSubscriptions() {
        return this.iapPlugin.manageSubscriptions()
    }


    /**
     * Restore purchases
     * @return {Promise<*>}
     */
    async restorePurchases() {
        const res = await this.iapPlugin.restorePurchases();

        if (res?.status && res.status !== "error") {
            await this.update();
        }

        return res;
    }

    /**
     * Checks if the test mode is enabled.
     *
     * @returns {boolean} True if the test mode is enabled, otherwise false.
     */
    isTestModeEnabled() {
        return this.device.a2u.storage.get('settings.test.premium', 0) === 1;
    }

    /**
     * Gets the current state of the store.
     *
     * @returns {string} The current state of the store.
     */
    getStoreState() {
        return this.storeState;
    }

    /**
     * Check if user subscribed to product
     * @param productIds
     */
    async isOwned(productIds) {
        if (this.isTestModeEnabled()) {
            return true;
        }

        // Check if product ids is array
        if(!Array.isArray(productIds)) productIds = [productIds]

        // Get products list
        const products = await this.getProductList();

        // Check if products list is empty
        if (!Object.keys(products).length) {
            throw new ErrorAnswer('No products found', 'products_not_found');
        }

        // Check if any of products is owned
        return productIds.some((productId) => products[productId]?.active || products[productId]?.ownedAmount > 0);
    }

    /**
     * Checks if a specific product has been purchased.
     *
     * @param {string} productId - The ID of the product to check.
     * @returns {boolean} True if the product has been purchased, otherwise false.
     */
    checkIfPurchasedProduct(productId) {
        if (this.isTestModeEnabled()) {
            return true;
        }

        this.isOwned([productId]);

        return this.purchasesMap[productId];
    }

    /**
     * Checks if any product in a specific group has been purchased.
     *
     * @param {string} groupId - The ID of the group to check.
     * @returns {boolean} True if any product in the group has been purchased, otherwise false.
     */
    checkIfPurchasedGroup(groupId) {
        if (this.isTestModeEnabled()) {
            return true;
        }

        const products = Object.entries(this.meta)
          .filter(([, meta]) => meta.group === groupId)
          .map(([productId]) => productId);

        this.isOwned(products);

        return this.groupsPurchasesMap[groupId];
    }

    /**
     * Get product owned amount
     * @param productId
     */
    async ownedAmount(productId) {

        // Get products list
        const products = await this.getProductList()

        // Return owned amount
        return products[productId]?.ownedAmount || 0
    }

    /**
     * Wait for store ready state
     * @param timeout
     * @return {Promise<unknown>}
     */
    async waitForStoreReady(timeout = 30) {

        // Ready
        if(!['updating'].includes(this.storeState)) return;

        // Wait for store ready state
        return new Promise((resolve, reject) => {

            // Timeouts
            let tm, interval

            // Set timeout
            tm = setTimeout(() => {
                clearInterval(interval)
                reject("timeout")
            }, timeout * 1000);

            // Wait for ready state
            interval = setInterval(() => {
                if(this.storeState !== 'updating') {
                    clearTimeout(tm)
                    clearInterval(interval)
                    resolve()
                }
            }, 300)
        })
    }

    /**
     * Check if user subscribed any of products
     */
    async isSubscribed(timeout = 30) {
        if (this.isTestModeEnabled()) {
            return true;
        }

        // Get products list
        const products = await this.getProductList(timeout);

        // Check if products list is empty
        if (!Object.keys(products).length) {
            throw new ErrorAnswer('No products found', 'products_not_found');
        }

        // Check if any of products is owned
        return Object.values(products).some((product) => product?.active);
    }

    /**
     * Marks a specific product as purchased.
     * Updates the `purchasesMap` and `groupsPurchasesMap` to reflect the purchase status.
     *
     * @param {string} productId - The ID of the product to mark as purchased.
     * @private
     */
    _markAsPurchased(productId) {
        this.purchasesMap[productId] = true;

        const meta = this.meta[productId];

        if (!this.groupsPurchasesMap[meta.group]) {
            this.groupsPurchasesMap[meta.group] = true;
        }
    }

    /**
     * Processes the purchase of a product.
     * This method takes a product ID and a checkout mode, converts the product ID to a platform-specific ID,
     * initiates the purchase process through the IAP plugin, and handles the result. If the purchase is successful,
     * the result is returned. If the user cancels the purchase, an error with the message "cancel" is thrown.
     * For any other outcome, an error with the message "error" is thrown.
     *
     * @async
     * @param {string} productId - The ID of the product to purchase.
     * @param {string} checkoutMode - The mode of checkout, e.g., 'hosted'.
     * @returns {Promise<Object>} A promise that resolves with the purchase result object on success.
     * @throws {Error} Throws an error with a message indicating the reason for failure ('cancel' or 'error').
     */
    async purchase(productId, checkoutMode = 'hosted') {
        try {
            // Get platform-specific id from product id
            const platformProductId = this.fromA2UToPlatform[productId]

            // Order product
            const result = await this.iapPlugin.purchase({
                productId: platformProductId,
                checkoutMode,
            });

            // Success
            this._markAsPurchased(productId);

            return result;
        } catch (e) {
            if (e instanceof ErrorAnswer) {
                throw e;
            }

            throw new ErrorAnswer(e.message, 'error');
        }
    }

    /**
     * Get a product list
     * @param timeout - wait timeout in seconds
     */
    async getProductList(timeout = 30) {

        // Init products
        const products = {};

        // Get products list
        if(this.device.getPlatform() === 'web' && this.targetPlatform !== 'web') {

            // Just setting products from config
            let i = 0
            for (const pr of this.config.products || []) {
                products[pr.productId] = {
                    id: pr.productId,
                    priceTitle: 'XX',
                    title: `Product ${pr.productId} title`,
                    priceValue: 100 * (i + 1),
                    billingPeriod: 'P1Y',
                    trialPeriod: i === 0 ? 'P1W' : false,
                    type: 'subscription'
                }
                i++;
            }
        } else {
            // Wait for store ready state
            await this.waitForStoreReady(timeout)

            const storeProducts = (await this.iapPlugin.getProducts())?.items || [];

            if (!storeProducts?.length) {
                this.logError(new Error('IAP Store: no products found'));
            }

            // Store to products
            for (const pr of storeProducts) {

                // Get a2u id from platform-specific id
                const prodId = this.fromPlatformToA2U[pr.id]

                // Set product by id
                products[prodId] = Object.assign({}, pr, {
                    id: prodId
                });
            }

            if (this.waitSyncPurchases instanceof Promise) {
                await this.waitSyncPurchases;
            }

            // Sync purchases
            return Object.fromEntries(
              Object.entries(products).map(([productId, product]) => {
                  // Check if the product is purchased
                  const isPurchased = this.syncPurchasesMap[productId] || product.active;

                  // Mark the product as purchased if necessary
                  if (isPurchased) {
                      this._markAsPurchased(productId);
                  }

                  return [productId, {
                      ...product,
                      active: isPurchased,
                  }];
              })
            );
        }

        // Return product list
        return products;
    }

    /**
     * Synchronizes the local purchases with the server.
     * This method attempts to find a server module configured for in-app purchase (IAP) synchronization.
     * If such a module is found, it constructs a payload with product IDs and their corresponding platform-specific IDs,
     * then sends this data to the server's IAP synchronization endpoint. The server response is used to update the local
     * state of each product, marking them as active if the server indicates they have been purchased.
     * In case of an error during this process, the original products object is returned without modification.
     *
     * @async
     * @param {Object} products - An object containing the products to be synchronized, keyed by product ID.
     * @returns {Promise<Object>} A promise that resolves to an object containing the updated products.
     */
    async syncPurchases() {
        try {
            // Check if the user is logged in
            if (!this.device.a2u.context.app.auth().isLoggedIn()) {
                return;
            }

            // Find a server module that is enabled for IAP synchronization
            const syncModule = Object.values(this.device.a2u.source.modules)
              .find((m) => m.type === 'server' && m?.iapSyncEnable);

            // Extract the endpoint URL from the sync module
            const endpoint = syncModule?.endpointUrl;

            // If no endpoint is found, return the products as is
            if (!endpoint) {
                return;
            }

            // Get the current module ID
            const moduleId = this.device.a2u.getModuleId();

            // Construct the payload for the server call
            this.syncPurchasesMap = await this.device.a2u.context.app.client.call(
              `${endpoint}/iap-sync`,
              'syncPurchases',
              moduleId,
              Object.fromEntries(
                Object.entries(this.meta).map(([productId, meta]) => [
                    productId,
                    this.targetPlatform === 'web' ? meta?.googleId : meta?.stripeId,
                ])
              ),
            );
        } catch (e) {
            // Log and return the products as is in case of an error
            console.error('syncPurchases error', e);
        }
    }

    /**
     * This method checks if the trial period is available for the given products.
     *
     * @async
     * @param {string|string[]} productsId - A single product ID, an array of product IDs, or '*' to check all products.
     * @returns {Promise<boolean>} Returns true if all specified products have a trial period, false otherwise.
     *
     * @example
     * // Check if a single product has a trial period
     * const hasTrial = await checkTrialPeriod('product1');
     *
     * @example
     * // Check if multiple products have a trial period
     * const hasTrial = await checkTrialPeriod(['product1', 'product2']);
     *
     * @example
     * // Check if all products have a trial period
     * const hasTrial = await checkTrialPeriod('*');
     */
    async checkTrialPeriod(productsId) {
        // Fetch the list of all products
        const products = await this.getProductList();

        // If productsId is '*', check all products, otherwise check only the specified products
        const checkProducts = productsId !== '*' ? [productsId].flat() : null;

        // Filter the products based on the checkProducts
        const filteredProducts = Object.values(products)
          .filter((product) => !checkProducts || checkProducts.includes(product.id));

        // Return true if all specified products have a trial period, false otherwise
        return !filteredProducts.some((product) => !product.trialPeriod);
    }

    /**
     * Log
     * @param params
     */
    log(...params) {
        console.log('IAPManager:', ...params);

        try {
            const message = typeof params[0] === 'string' ? params[0] : null;

            this.device.a2u.debugLogger.log({
                type: 'debug',
                message: `IAPManager: ${message}`,
                data: {
                    component: {
                        properties: message ? params.slice(1) : params,
                    },
                },
            });
        } catch (e) {
            console.error('Failed to log debug', e);
        }
    }

    /**
     * Logs an error using the device's A2U error logger.
     * If logging fails, it logs the failure to the console.
     *
     * @param {Error|string} error - The error object or message to be logged.
     */
    logError(error) {
        try {
            this.device.a2u.errorLogger.log(error);
        } catch (e) {
            console.error('Failed to log error', e);
        }
    }
}
