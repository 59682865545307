import {ErrorAnswer} from 'a2u-renderer-common/src/utils/ErrorAnswer';
import { BasePlatformAdapter } from './BasePlatformAdapter';

/**
 * AbIAPClientStripe class.
 *
 * This class handles interactions with Stripe for in-app purchases, including initializing product data,
 * updating product lists, making purchases, managing subscriptions, and restoring purchases.
 */
export class AbIAPClientStripe extends BasePlatformAdapter {
  /**
   * Stores product data from the schema.
   * @static
   */
  static productsData = new Map;

  /**
   * Stores product information for UI rendering.
   * @static
   */
  static products = new Map;

  /**
   * Axios instance for making HTTP requests.
   * @static
   */
  static axios;

  /**
   * Endpoint URL for Stripe-related API calls.
   * @static
   */
  static endpoint;

  /**
   * Initializes the class with necessary data.
   *
   * @static
   * @param {Object} data - Initialization data including axios client, endpoint, and products.
   */
  static async init(data) {
    this.axios = data.client.getAxios();
    this.endpoint = data.endpoint;
    this.stripe = data.stripe;
    this.runMode = data.runMode;
    this.device = data.device;
    this.dialogPlugin = data.dialogPlugin;

    for (const product of data?.products || []) {
      this.productsData.set(product.id, product);
    }
  }

  /**
   * Updates the list of products from the server.
   *
   * @static
   * @throws {Error} If the endpoint is not set.
   */
  static async update() {
    if (!this.endpoint) {
      throw 'Endpoint is not set';
    }

    const ids = [...this.productsData.values()].map((p) => p.id);

    const { data } = await this.axios.post(`${this.endpoint}/stripe/products`, [ids]);

    for (const product of data.products) {
      this.products.set(product.id, product);
    }
  }

  /**
   * Retrieves the list of products.
   *
   * @static
   * @returns {Object} An object containing an array of product items.
   */
  static async getProducts() {
    return {
      items: [...this.products.values()],
    }
  }

  /**
   * Initiates a purchase for a specified product.
   *
   * @static
   * @param {Object} params - Parameters for the purchase.
   * @param {string} params.productId - The ID of the product to purchase.
   * @throws {Error} If the endpoint is not set or the product is not found.
   */
  static async purchase({ productId, checkoutMode }) {
    if (!this.endpoint) {
      throw new ErrorAnswer('Endpoint is not set', 'error');
    }

    const product = this.products.get(productId);
    const productData = this.productsData.get(productId);

    if (!product?.id || !productData?.id) {
      throw new ErrorAnswer('Product not found', 'error');
    }

    const { data } = await this.axios.post(`${this.endpoint}/stripe/createSession`, [
      product.id,
      product.priceId,
      productData.type,
      window.location.href,
      checkoutMode || 'hosted',
    ]);

    // If the mode is embedded, use the StripeWebPlugin to mount the session
    if (checkoutMode === 'embedded') {
      if (!data.clientSecret) {
        throw new ErrorAnswer('Error creating session', 'error');
      }

      const plugin = this.device.getPlugin('StripeWebPlugin');

      if (!plugin) {
        throw new ErrorAnswer('StripeWebPlugin is not installed', 'error');
      }

      // Create an instance of the plugin
      const instance = new plugin(this.dialogPlugin);

      // Retrieve the API key based on the run mode
      const apiKey = this.stripe?.apiKey[this.runMode === 'release' ? 'release' : 'stage'];

      if (!apiKey) {
        throw new ErrorAnswer('Stripe API Key is not set in the app settings', 'error');
      }

      // Mount the session
      const result = await instance.mount(this.device.a2u, apiKey, data.clientSecret, productData.type);

      if (result?.state === 'cancel') {
        throw new ErrorAnswer('Purchase was canceled', 'cancel');
      }

      // If the session was successful, update the product list
      if (result?.state === 'success') {
        this.axios.post(`${this.endpoint}/stripe/syncPurchases`, []).then(() => {
          this.update();
        });
      }

      const purchaseResult = {
        ...result,
        ...(result?.state === 'success' ? {
          price: product.priceValue,
          currency: product.currency,
        } : {})
      };

      if (purchaseResult?.state === 'success') {
        this.emitEvent('successPurchase', productId, purchaseResult);
      }

      return purchaseResult;
    } else {
      if (!data.url) {
        throw new ErrorAnswer('Error creating session', 'error');
      }

      window.location.href = data.url;
    }
  }

  /**
   * Initiates the process for managing subscriptions.
   * This method triggers a session creation for managing subscriptions on the Stripe platform.
   * It first checks if the Stripe API endpoint is set. If not, it throws an error.
   * Then, it makes a POST request to the Stripe API to create a session for subscription management,
   * passing the current window location as a parameter.
   * If the session is successfully created, the user is redirected to the session URL provided by Stripe.
   * This allows the user to manage their subscriptions directly on the Stripe platform.
   *
   * @static
   * @async
   * @throws {Error} If the Stripe API endpoint is not set, indicating that the class was not properly initialized.
   * @throws {Error} If there is an error creating the session, such as a failure to receive a valid session URL from Stripe.
   */
  static async manageSubscriptions() {
    if (!this.endpoint) {
      throw 'Endpoint is not set';
    }

    const { data } = await this.axios.post(`${this.endpoint}/stripe/manageSubscriptions`, [
      window.location.href,
    ]);

    if (!data.url) {
      throw 'Error creating session';
    }

    window.location.href = data.url;
  }

  /**
   * Placeholder for restoring purchases. Currently not implemented.
   *
   * @static
   * @returns {Object} An object indicating the operation was successful.
   */
  static async restorePurchases() {
    console.warn('restorePurchases is not implemented for Stripe IAP client.')

    return {
      status: 'success',
    };
  }
}
